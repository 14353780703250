
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Private Webinar',
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      showRejectPrivateWebinar: false,
      showAcceptPrivateWebinar: false,
      formRejectPrivateWebinar: {
        reject_note: '' as string,
      },
      errorDeadline: false,
      privateWebinar: [] as any,
      selectedPrivateWebinar: {} as any,
      showDetailPrivateWebinar: false,
      loadingConfirmPrivateWebinar: false,
      filterPrivateWebinar: 'requested' as string,
    }
  },
  mounted() {
    this.getPrivateWebinar()
  },
  computed: {
    getPrivateWebinarMentorData(): any {
      return this.$store.getters['mentorStore/getPrivateWebinarMentor']
    },
    checkFormReject(): boolean {
      if (this.formRejectPrivateWebinar.reject_note) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    changeFilterPrivateWebinar(filter: string) {
      this.isLoading = true
      this.filterPrivateWebinar = filter
      this.privateWebinar = []
      if (this.getPrivateWebinarMentorData.length > 0) {
        let i = 1
        this.getPrivateWebinarMentorData.forEach((el: any) => {
          const noteSplit = el.note.split(/\r\n|\n\r|\n|\r/)
          if (filter == 'requested') {
            if (el.status == 0) {
              this.privateWebinar.push({
                ...el,
                material: noteSplit[0],
                reason: noteSplit[1],
                no: i,
              })
              i++
            }
          } else if (filter == 'approved') {
            if (el.status == 1) {
              this.privateWebinar.push({
                ...el,
                material: noteSplit[0],
                reason: noteSplit[1],
                no: i,
              })
              i++
            }
          } else if (filter == 'rejected') {
            if (el.status == -1) {
              this.privateWebinar.push({
                ...el,
                material: noteSplit[0],
                reason: noteSplit[1],
                no: i,
              })
              i++
            }
          } else {
            this.privateWebinar.push({
              ...el,
              material: noteSplit[0],
              reason: noteSplit[1],
              no: i,
            })
            i++
          }
        })
      }
      this.isLoading = false
    },
    async getPrivateWebinar() {
      this.isLoading = true
      await this.$store.dispatch(
        'mentorStore/fetchPrivateWebinarList',
        this.classroomCode
      )
      this.changeFilterPrivateWebinar(this.filterPrivateWebinar)
    },
    formatDateLong(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY HH:mm')
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm:00')
    },
    showModalRejectPrivateWebinar(data: any) {
      this.selectedPrivateWebinar = data
      this.showRejectPrivateWebinar = true
      this.formRejectPrivateWebinar.reject_note = ''
    },
    closeRejectPrivateWebinar() {
      this.selectedPrivateWebinar = {}
      this.showRejectPrivateWebinar = false
      this.formRejectPrivateWebinar.reject_note = ''
    },
    async rejectPrivateWebinar() {
      this.loadingConfirmPrivateWebinar = true
      await ClassroomModel.rejectPrivateWebinar(
        this.selectedPrivateWebinar.id,
        this.formRejectPrivateWebinar
      )
        .then(res => {
          this.loadingConfirmPrivateWebinar = false
          if (res.status) {
            this.closeRejectPrivateWebinar()
            this.getPrivateWebinar()
            this.toast.success('Berhasil menolak permintaan private webinar')
          } else {
            this.toast.error('Terjadi Kesalahan, silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.loadingConfirmPrivateWebinar = false
          this.toast.error(err.response.data.errors[0].message)
        })
    },
    openAcceptPrivateWebinar(data: any) {
      this.selectedPrivateWebinar = data
      this.showAcceptPrivateWebinar = true
    },
    closeAcceptPrivateWebinar() {
      this.selectedPrivateWebinar = {}
      this.showAcceptPrivateWebinar = false
    },
    async acceptPrivateWebinar() {
      this.loadingConfirmPrivateWebinar = true
      await ClassroomModel.acceptPrivateWebinar(this.selectedPrivateWebinar.id)
        .then(async res => {
          this.loadingConfirmPrivateWebinar = false
          await this.getPrivateWebinar()
          this.closeAcceptPrivateWebinar()
          this.toast.success('Berhasil menerima permintaan private webinar')
        })
        .catch(err => {
          this.loadingConfirmPrivateWebinar = false
          this.toast.error(err.response.data.errors[0].message)
        })
    },
    openDetailPrivateWebinar(data: any) {
      this.selectedPrivateWebinar = data
      this.showDetailPrivateWebinar = true
    },
    closeDetailPrivateWebinar() {
      this.selectedPrivateWebinar = {}
      this.showDetailPrivateWebinar = false
    },
    webinarTimeFormatted(start: string, end: string) {
      const startDate = moment(start)
        .locale('id')
        .format('dddd, DD MMMM YYYY HH:mm')
      if (end == '1') {
        const endDate = moment(start)
          .add(1, 'hours')
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      } else {
        const endDate = moment(end)
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      }
    },
  },
})
