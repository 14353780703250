<template>
  <div class="w-full md:mx-2 rounded">
    <div class="mb-10 bg-white">
      <div
        class="w-full flex flex-col md:flex-row justify-between border-b border-gray-300 mb-3 items-start px-2"
      >
        <p
          class="p-3 font-desain text-[#212121] font-semibold text-lg flex-shrink-0"
        >
          List Permintaan Konsultasi Desain
        </p>
        <div
          v-dragscroll
          class="flex md:mt-2 mb-3 md:mb-0 flex-row items-center overflow-auto scroll-hide space-x-2"
        >
          <button
            ref="requested"
            @click="changeFilterPrivateWebinar('requested')"
            class="ml-2 flex flex-shrink-0 items-center px-4 py-2 font-solusi text-sm font-semibold rounded-full"
            :class="
              filterPrivateWebinar == 'requested'
                ? 'text-white bg-[#00446F]'
                : 'text-[#00446F] bg-[#F5F6F8]'
            "
          >
            <svg
              v-if="filterPrivateWebinar == 'requested'"
              class="w-4 h-4 mr-1.5"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6.5" cy="6" r="6" fill="white" />
              <path
                d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                fill="#00446F"
              />
            </svg>
            Pengajuan Konsultasi
          </button>
          <button
            ref="approved"
            @click="changeFilterPrivateWebinar('approved')"
            class="flex flex-shrink-0 items-center px-4 py-2 font-solusi text-sm font-semibold rounded-full"
            :class="
              filterPrivateWebinar == 'approved'
                ? 'text-white bg-[#00446F]'
                : 'text-[#00446F] bg-[#F5F6F8]'
            "
          >
            <svg
              v-if="filterPrivateWebinar == 'approved'"
              class="w-4 h-4 mr-1.5"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6.5" cy="6" r="6" fill="white" />
              <path
                d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                fill="#00446F"
              />
            </svg>
            Disetujui
          </button>
          <button
            ref="rejected"
            @click="changeFilterPrivateWebinar('rejected')"
            class="flex flex-shrink-0 items-center px-4 py-2 font-solusi text-sm font-semibold rounded-full"
            :class="
              filterPrivateWebinar == 'rejected'
                ? 'text-white bg-[#00446F]'
                : 'text-[#00446F] bg-[#F5F6F8]'
            "
          >
            <svg
              v-if="filterPrivateWebinar == 'rejected'"
              class="w-4 h-4 mr-1.5"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6.5" cy="6" r="6" fill="white" />
              <path
                d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                fill="#00446F"
              />
            </svg>
            Ditolak
          </button>
          <button
            ref="all"
            @click="changeFilterPrivateWebinar('all')"
            class="flex flex-shrink-0 items-center px-4 py-2 font-solusi text-sm font-semibold rounded-full"
            :class="
              filterPrivateWebinar == 'all'
                ? 'text-white bg-[#00446F]'
                : 'text-[#00446F] bg-[#F5F6F8]'
            "
          >
            <svg
              v-if="filterPrivateWebinar == 'all'"
              class="w-4 h-4 mr-1.5"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6.5" cy="6" r="6" fill="white" />
              <path
                d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                fill="#00446F"
              />
            </svg>
            Semua
          </button>
        </div>
      </div>
      <div class="w-full m-auto">
        <DataTable
          :value="privateWebinar"
          :paginator="true"
          :rows="10"
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          class="p-datatable-sm font-solusi text-sm"
          currentPageReportTemplate="Menampilkan {first} hingga {last} dari {totalRecords} konsultasi desain"
          :totalRecords="privateWebinar.length"
          responsiveLayout="scroll"
          :rowHover="true"
          :loading="isLoading"
        >
          <template #empty>
            <div class="w-full flex flex-col items-center justify-center py-8">
              <img
                src="/assets/images/modal/ilustration-webinar.svg"
                class="w-3/4 md:w-1/3 m-auto"
                alt=""
              />
              <div
                class="text-center font-semibold text-accent font-solusi text-base mt-2"
              >
                Tidak ada daftar konsultasi desain yang ditampilkan
              </div>
            </div>
          </template>
          <template #loading>
            <div class="text-center font-semibold font-solusi text-base">
              Loading data konsultasi desain. Please wait.
            </div>
          </template>
          <Column
            field="name"
            header="No."
            headerClass="w-12 text-[#212121]"
            bodyClass="font-solusi font-semibold text-sm text-[#383838]"
          >
            <template #body="{data}"> {{ data.no }}. </template>
          </Column>
          <Column
            field="name"
            header="Nama Member"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ data?.user ? data.user.name : '-' }}
            </template>
          </Column>
          <Column
            field="material"
            header="Materi"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ data.material }}
            </template>
          </Column>
          <!-- <Column
            field="reason"
            header="Keterangan"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ data.reason }}
            </template>
          </Column> -->
          <Column
            field="request_started_at"
            header="Waktu yang diajukan Member"
            bodyClass="font-solusi font-medium text-sm text-[#383838] w-80"
          >
            <template #body="{data}">
              {{ formatDateLong(data.request_started_at) }} WIB
            </template>
          </Column>
          <Column
            field="name"
            header="Aksi"
            headerClass="w-44"
            bodyClass="font-solusi font-medium text-sm flex w-auto"
          >
            <template #body="{data}">
              <button
                v-tooltip.top="'Detail Konsultasi Desain'"
                @click="openDetailPrivateWebinar(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-show mr-1 outline-none focus:outline-none"
              >
                <svg
                  class="w-4 h-4"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00009 13.3337C2.97513 13.3337 0.769722 8.5199 0.666682 8.33366C0.586056 8.18866 0.586161 7.812 0.666786 7.66699C0.769911 7.48074 2.97513 2.66699 8.00009 2.66699C13.025 2.66699 15.2304 7.81409 15.3334 8.00033C15.4141 8.14533 15.4141 8.18865 15.3334 8.33366C15.2303 8.51991 13.025 13.3337 8.00009 13.3337ZM1.66677 8.00033C2.2374 8.86532 4.17753 12.3337 8.00009 12.3337C11.8345 12.3337 13.7647 8.86645 14.3335 8.00021C13.7628 7.13522 11.8226 3.66699 8.00009 3.66699C4.16569 3.66699 2.23557 7.13409 1.66677 8.00033ZM8.00009 10.8802C6.41196 10.8802 5.12009 9.58834 5.12009 8.00021C5.12009 6.41208 6.41196 5.12021 8.00009 5.12021C9.58821 5.12021 10.8801 6.41208 10.8801 8.00021C10.8801 9.58834 9.58821 10.8802 8.00009 10.8802ZM8.00009 6.08021C6.94134 6.08021 6.08009 6.94146 6.08009 8.00021C6.08009 9.05896 6.94134 9.92021 8.00009 9.92021C9.05884 9.92021 9.92009 9.05896 9.92009 8.00021C9.92009 6.94146 9.05884 6.08021 8.00009 6.08021Z"
                    fill="currentColor"
                  />
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Detail</span
                >
              </button>
              <div
                v-if="data.status == 1"
                class="flex-grow flex justify-center items-center"
              >
                <span
                  class="font-solusi text-sm font-semibold inline-block py-1 px-2 rounded text-[#2FA742] bg-green-100 border border-[#2FA742]"
                >
                  Disetujui
                </span>
              </div>
              <div
                v-else-if="data.status == -1"
                class="flex-grow flex justify-center items-center"
              >
                <span
                  class="font-solusi text-sm font-semibold inline-block py-1 px-2 rounded text-[#DE1306] bg-red-100 border border-[#DE1306]"
                >
                  Ditolak
                </span>
              </div>
              <div v-else class="flex w-auto">
                <button
                  v-tooltip.top="'Terima Konsultasi Desain'"
                  @click="openAcceptPrivateWebinar(data)"
                  class="px-3 py-2 text-center rounded-full flex items-center btn-accept mr-1 outline-none focus:outline-none"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#2FBD71" />
                    <g style="mix-blend-mode:lighten">
                      <path
                        d="M6.18277 11.9412C6.3659 12.129 6.62072 12.2355 6.88703 12.2355C6.88961 12.2355 6.89232 12.2355 6.89516 12.2355C7.16457 12.2332 7.42081 12.1225 7.60252 11.9295L12.9232 6.27858C13.2839 5.89547 13.2558 5.30134 12.8609 4.95154C12.4658 4.602 11.8531 4.62893 11.4924 5.01191L6.87501 9.91578L5.43769 8.43984C5.07013 8.06261 4.45692 8.04583 4.06818 8.40189C3.67917 8.75807 3.66187 9.35246 4.02904 9.72968L6.18277 11.9412Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span
                    class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                    >Terima</span
                  >
                </button>
                <button
                  v-tooltip.top="'Tolak Konsultasi Desain'"
                  @click="showModalRejectPrivateWebinar(data)"
                  class="px-3 py-2 text-center rounded-full flex items-center btn-reject mr-1 outline-none focus:outline-none"
                >
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM5.86569 4.23431C5.55327 3.9219 5.04673 3.9219 4.73431 4.23431C4.4219 4.54673 4.4219 5.05327 4.73431 5.36569L7.36863 8L4.73431 10.6343C4.4219 10.9467 4.4219 11.4533 4.73431 11.7657C5.04673 12.0781 5.55327 12.0781 5.86569 11.7657L8.5 9.13137L11.1343 11.7657C11.4467 12.0781 11.9533 12.0781 12.2657 11.7657C12.5781 11.4533 12.5781 10.9467 12.2657 10.6343L9.63137 8L12.2657 5.36569C12.5781 5.05327 12.5781 4.54673 12.2657 4.23431C11.9533 3.9219 11.4467 3.9219 11.1343 4.23431L8.5 6.86863L5.86569 4.23431Z"
                      fill="currentColor"
                    />
                    <g style="mix-blend-mode:lighten">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM5.86569 4.23431C5.55327 3.9219 5.04673 3.9219 4.73431 4.23431C4.42189 4.54673 4.42189 5.05327 4.73431 5.36569L7.36863 8L4.73431 10.6343C4.42189 10.9467 4.42189 11.4533 4.73431 11.7657C5.04673 12.0781 5.55327 12.0781 5.86569 11.7657L8.5 9.13137L11.1343 11.7657C11.4467 12.0781 11.9533 12.0781 12.2657 11.7657C12.5781 11.4533 12.5781 10.9467 12.2657 10.6343L9.63137 8L12.2657 5.36569C12.5781 5.05327 12.5781 4.54673 12.2657 4.23431C11.9533 3.9219 11.4467 3.9219 11.1343 4.23431L8.5 6.86863L5.86569 4.23431Z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>
                  <span
                    class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                    >Tolak</span
                  >
                </button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showRejectPrivateWebinar"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showRejectPrivateWebinar"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51 scroll-custom"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]"
          >
            <!--header-->
            <div class="flex items-start justify-end p-3 pb-0 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeRejectPrivateWebinar()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div class="px-6 flex-auto overflow-x-hiden overflow-y-auto">
              <h3
                class="text-xl font-semibold text-[#212121] font-desain text-center"
              >
                Tolak Permintaan Konsultasi Desain
              </h3>
              <div class="mt-3 mb-1">
                <textarea
                  name="reason"
                  id="reason"
                  cols="30"
                  rows="5"
                  class="rounded-md font-solusi text-[#212121] text-sm p-2 border border-gray-300 w-full outline-none focus:outline-none focus:ring ring-[#0A72ED] ring-opacity-50 duration-150"
                  placeholder="Alasan penolakan"
                  v-model="formRejectPrivateWebinar.reject_note"
                ></textarea>
              </div>
            </div>
            <div class="flex justify-evenly p-4">
              <button
                @click="closeRejectPrivateWebinar"
                class="px-9 py-1.5 font-solusi font-semibold rounded-full outline-none focus:outline-none border-dark-blue text-[#00446F]"
              >
                Batal
              </button>
              <button
                class="px-6 py-1.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none border focus:outline-none duration-300 flex items-center"
                :class="[
                  checkFormReject
                    ? 'bg-[#00446F] text-white'
                    : 'bg-[#F5F6F8] text-[#9E9E9E] cursor-not-allowed',
                  loadingConfirmPrivateWebinar ? 'cursor-wait' : '',
                ]"
                @click="rejectPrivateWebinar"
                :disabled="!checkFormReject"
              >
                <svg
                  v-if="loadingConfirmPrivateWebinar"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="spinner"
                  class="w-4 h-4 mr-2 animate-spin"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                  ></path>
                </svg>
                Tolak Konsultasi Desain
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="showAcceptPrivateWebinar"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showAcceptPrivateWebinar"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeAcceptPrivateWebinar"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-end p-3 pb-0 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeAcceptPrivateWebinar()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div
              class="pt-0 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto"
            >
              <img
                src="/assets/images/modal/illustration-test-completed.svg"
                class="w-1/2 m-auto"
                alt=""
              />
              <p
                class="font-desain font-semibold my-3 text-center text-[#212121] text-xl"
              >
                Terima Konsultasi Desain
              </p>
              <p
                class="font-solusi font-medium text-base text-center text-[#383838]"
              >
                Apakah Anda yakin ingin konfirmasi permintaan konsultasi desain
                ini?
              </p>
              <div class="flex justify-evenly mt-8">
                <button
                  @click="closeAcceptPrivateWebinar"
                  class="bg-[#00446F] px-9 py-2 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none"
                >
                  Tidak
                </button>
                <button
                  @click="acceptPrivateWebinar"
                  class="px-9 py-2 font-solusi font-semibold rounded-full outline-none focus:outline-none border-dark-blue text-[#00446F]"
                >
                  Ya, saya yakin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="showDetailPrivateWebinar"
        class="opacity-50 fixed inset-0 z-51 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showDetailPrivateWebinar"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeDetailPrivateWebinar"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <h3
                class="text-xl font-semibold text-[#212121] font-desain text-center"
              >
                Detail Permintaan Konsultasi Desain
              </h3>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeDetailPrivateWebinar()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div
              class="pt-3 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto scroll-custom"
            >
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Member:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{
                    selectedPrivateWebinar?.user
                      ? selectedPrivateWebinar.user.name
                      : '-'
                  }}
                </p>
              </div>
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Materi:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ selectedPrivateWebinar.material }}
                </p>
              </div>
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Keterangan Member:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ selectedPrivateWebinar.reason }}
                </p>
              </div>
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Waktu yang diajukan member:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{
                    formatDateLong(selectedPrivateWebinar.request_started_at)
                  }}
                  WIB
                </p>
              </div>
              <div v-if="selectedPrivateWebinar.status == 1" class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Waktu Webinar Berlangsung:
                </span>
                <p
                  v-if="selectedPrivateWebinar.started_at"
                  class="font-solusi text-[#009DFF] ml-3 font-semibold"
                >
                  {{
                    webinarTimeFormatted(
                      selectedPrivateWebinar.started_at,
                      expired_at
                    )
                  }}*
                </p>
                <p v-else class="font-solusi text-[#009DFF] ml-3 font-semibold">
                  {{
                    webinarTimeFormatted(
                      selectedPrivateWebinar.request_started_at,
                      '1'
                    )
                  }}*
                </p>
              </div>
              <div v-if="selectedPrivateWebinar.status == 1" class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Link Webinar:
                </span>
                <a
                  v-if="selectedPrivateWebinar?.link"
                  :href="selectedPrivateWebinar?.link"
                  target="_blank"
                  class="font-solusi text-blue-600 hover:text-[#274977]0 ml-3"
                >
                  {{ selectedPrivateWebinar?.link }}
                </a>
                <p v-else class="font-solusi text-[#DE1306] ml-3 font-semibold">
                  Belum diset Admin
                </p>
              </div>
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Status:
                </span>
                <p
                  class="font-solusi ml-3 font-semibold"
                  :class="
                    selectedPrivateWebinar.status == 0
                      ? 'text-[#0A72ED]'
                      : selectedPrivateWebinar.status == 1
                      ? 'text-[#2FA742]'
                      : 'text-[#DE1306]'
                  "
                >
                  {{
                    selectedPrivateWebinar.status == 0
                      ? 'Perlu Konfirmasi Mentor'
                      : selectedPrivateWebinar.status == 1
                      ? 'Disetujui'
                      : 'Ditolak'
                  }}
                </p>
              </div>
              <div v-if="selectedPrivateWebinar.status == -1" class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Ditolak pada:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ formatDateLong(selectedPrivateWebinar.rejected_at) }}
                  WIB
                </p>
              </div>
              <div v-else-if="selectedPrivateWebinar.status == 1" class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Disetujui pada:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ formatDateLong(selectedPrivateWebinar.confirmed_at) }}
                  WIB
                </p>
              </div>
              <div v-if="selectedPrivateWebinar.status == -1" class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Alasan Ditolak:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ selectedPrivateWebinar.reject_note }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Private Webinar',
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      showRejectPrivateWebinar: false,
      showAcceptPrivateWebinar: false,
      formRejectPrivateWebinar: {
        reject_note: '' as string,
      },
      errorDeadline: false,
      privateWebinar: [] as any,
      selectedPrivateWebinar: {} as any,
      showDetailPrivateWebinar: false,
      loadingConfirmPrivateWebinar: false,
      filterPrivateWebinar: 'requested' as string,
    }
  },
  mounted() {
    this.getPrivateWebinar()
  },
  computed: {
    getPrivateWebinarMentorData(): any {
      return this.$store.getters['mentorStore/getPrivateWebinarMentor']
    },
    checkFormReject(): boolean {
      if (this.formRejectPrivateWebinar.reject_note) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    changeFilterPrivateWebinar(filter: string) {
      this.isLoading = true
      this.filterPrivateWebinar = filter
      this.privateWebinar = []
      if (this.getPrivateWebinarMentorData.length > 0) {
        let i = 1
        this.getPrivateWebinarMentorData.forEach((el: any) => {
          const noteSplit = el.note.split(/\r\n|\n\r|\n|\r/)
          if (filter == 'requested') {
            if (el.status == 0) {
              this.privateWebinar.push({
                ...el,
                material: noteSplit[0],
                reason: noteSplit[1],
                no: i,
              })
              i++
            }
          } else if (filter == 'approved') {
            if (el.status == 1) {
              this.privateWebinar.push({
                ...el,
                material: noteSplit[0],
                reason: noteSplit[1],
                no: i,
              })
              i++
            }
          } else if (filter == 'rejected') {
            if (el.status == -1) {
              this.privateWebinar.push({
                ...el,
                material: noteSplit[0],
                reason: noteSplit[1],
                no: i,
              })
              i++
            }
          } else {
            this.privateWebinar.push({
              ...el,
              material: noteSplit[0],
              reason: noteSplit[1],
              no: i,
            })
            i++
          }
        })
      }
      this.isLoading = false
    },
    async getPrivateWebinar() {
      this.isLoading = true
      await this.$store.dispatch(
        'mentorStore/fetchPrivateWebinarList',
        this.classroomCode
      )
      this.changeFilterPrivateWebinar(this.filterPrivateWebinar)
    },
    formatDateLong(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY HH:mm')
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm:00')
    },
    showModalRejectPrivateWebinar(data: any) {
      this.selectedPrivateWebinar = data
      this.showRejectPrivateWebinar = true
      this.formRejectPrivateWebinar.reject_note = ''
    },
    closeRejectPrivateWebinar() {
      this.selectedPrivateWebinar = {}
      this.showRejectPrivateWebinar = false
      this.formRejectPrivateWebinar.reject_note = ''
    },
    async rejectPrivateWebinar() {
      this.loadingConfirmPrivateWebinar = true
      await ClassroomModel.rejectPrivateWebinar(
        this.selectedPrivateWebinar.id,
        this.formRejectPrivateWebinar
      )
        .then(res => {
          this.loadingConfirmPrivateWebinar = false
          if (res.status) {
            this.closeRejectPrivateWebinar()
            this.getPrivateWebinar()
            this.toast.success('Berhasil menolak permintaan private webinar')
          } else {
            this.toast.error('Terjadi Kesalahan, silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.loadingConfirmPrivateWebinar = false
          this.toast.error(err.response.data.errors[0].message)
        })
    },
    openAcceptPrivateWebinar(data: any) {
      this.selectedPrivateWebinar = data
      this.showAcceptPrivateWebinar = true
    },
    closeAcceptPrivateWebinar() {
      this.selectedPrivateWebinar = {}
      this.showAcceptPrivateWebinar = false
    },
    async acceptPrivateWebinar() {
      this.loadingConfirmPrivateWebinar = true
      await ClassroomModel.acceptPrivateWebinar(this.selectedPrivateWebinar.id)
        .then(async res => {
          this.loadingConfirmPrivateWebinar = false
          await this.getPrivateWebinar()
          this.closeAcceptPrivateWebinar()
          this.toast.success('Berhasil menerima permintaan private webinar')
        })
        .catch(err => {
          this.loadingConfirmPrivateWebinar = false
          this.toast.error(err.response.data.errors[0].message)
        })
    },
    openDetailPrivateWebinar(data: any) {
      this.selectedPrivateWebinar = data
      this.showDetailPrivateWebinar = true
    },
    closeDetailPrivateWebinar() {
      this.selectedPrivateWebinar = {}
      this.showDetailPrivateWebinar = false
    },
    webinarTimeFormatted(start: string, end: string) {
      const startDate = moment(start)
        .locale('id')
        .format('dddd, DD MMMM YYYY HH:mm')
      if (end == '1') {
        const endDate = moment(start)
          .add(1, 'hours')
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      } else {
        const endDate = moment(end)
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      }
    },
  },
})
</script>

<style scoped>
::v-deep(.p-column-title) {
  font-family: 'SolusiSans';
  font-weight: 700;
}
::v-deep(.p-paginator-current) {
  font-family: 'SolusiSans';
  font-weight: 600;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  font-family: 'SolusiSans';
  font-weight: 500;
}

.max-h-85 {
  max-height: 85vh;
}

.h-fit-content {
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
}

.border-dark-blue {
  border: 1px solid #00446f;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  border: 1px solid #009dff;
  color: #009dff;
  font-weight: 600;
  background-color: #fff;
}
.btn-show {
  color: #212121;
  border: 1px solid #212121;
}
.btn-edit {
  color: #00446f;
  border: 1px solid #00446f;
}
.btn-accept {
  color: #2fbd71;
  border: 1px solid #2fbd71;
}
.btn-reject {
  color: #de1306;
  border: 1px solid #de1306;
}
.btn-show:hover,
.btn-edit:hover,
.btn-accept:hover,
.btn-reject:hover {
  color: #fff;
  transition-duration: 500ms;
}
.btn-show:hover {
  background-color: #212121;
}
.btn-edit:hover {
  background-color: #00446f;
}
.btn-accept:hover {
  background-color: #2fbd71;
}
.btn-reject:hover {
  background-color: #de1306;
}
::v-deep(.custom-calendar .vc-container) {
  border-radius: 0.5rem;
  width: 100%;
}
::v-deep(.custom-calendar .vc-container .vc-header) {
  padding: 10px 16px;
}
::v-deep(.custom-calendar .vc-pane) {
  border-radius: 5px;
}
::v-deep(.custom-calendar .vc-title) {
  font-family: 'SolusiSans', sans-serif;
}
::v-deep(.custom-calendar .vc-day-content) {
  font-family: 'SolusiSans', sans-serif;
}
::v-deep(.custom-calendar .vc-title) {
  color: #383838;
  font-weight: 600;
  font-size: 1rem;
}
::v-deep(.custom-calendar .vc-container .vc-weeks) {
  padding: 0;
}
::v-deep(.custom-calendar .vc-container .vc-weekday) {
  background-color: #f6fbff;
  color: #000;
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}
::v-deep(.custom-calendar .in-next-month.is-not-in-month) {
  display: none;
}
::v-deep(.custom-calendar .vc-container .vc-day .cal-day-label) {
  color: #757575;
}
::v-deep(.custom-calendar .vc-container .on-left .vc-day-content) {
  color: #de1306;
}
::v-deep(.custom-calendar .vc-container .vc-weekday:first-child) {
  color: #de1306;
}
::v-deep(.custom-calendar .vc-container .is-today .cal-day-label) {
  width: fit-content;
  color: #00446f;
  font-weight: 600;
}
::v-deep(.custom-calendar .vc-arrows-container .vc-arrow) {
  color: #009dff;
}
</style>
